import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import cn from "classnames"

import Seo from "../components/seo"
import PageHeader from "../components/PageHeader"
import Logo from "../components/Logo"

const TreatmentsPage = () => {
  const {
    heroImage,
    TreatmentMassageImage,
    TreatmentPhysiotherapy,
    CrestLogo,
    HcpcLogo,
  } = useStaticQuery(graphql`
    query TreatmentsPageQuery {
      heroImage: file(relativePath: { eq: "hero/personilsed-for-you.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
      TreatmentMassageImage: file(
        relativePath: { eq: "treatments/massage.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            height: 500
            quality: 90
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      TreatmentPhysiotherapy: file(
        relativePath: { eq: "treatments/physiotherapy.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            height: 500
            quality: 90
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
      HcpcLogo: file(relativePath: { eq: "logos/hcpc.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            quality: 80
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      CrestLogo: file(relativePath: { eq: "logos/crest_csp.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            quality: 80
            layout: FIXED
            placeholder: NONE
          )
        }
      }
    }
  `)

  const heroImageData = getImage(heroImage)
  const treatmentMassageImageData = getImage(TreatmentMassageImage)
  const treatmentPhysiotherapyData = getImage(TreatmentPhysiotherapy)

  return (
    <main>
      <Seo
        title="Treatments | Relax and let us take care of you."
        desc="Maintenance massage, Ideal for those looking to relieve some aches and pains and promote general wellbeing in their life. Massage is an immense pain reliever and has profound calming effects for those with nerve and muscular pain. Suffer no longer, let us take care you. After a quick assessment, the massage will be catered for you and your needs. We also offer pre, post and intra event massage for competing athletes."
      />

      <PageHeader
        image={heroImageData}
        title="Treatments"
        text="Relax and let us take care you."
      />

      <section className="py-14">
        <div className="container">
          <div className="pb-5 grid gap-y-20">
            <TreatmentSection
              id="sports-massage"
              image={treatmentMassageImageData}
              name="Sports Massage"
              text="<p>Ideal for those looking to relieve some aches and pains and promote general wellbeing in their life.</p><p>Massage is an immense pain reliever and has profound calming effects for those with nerve and muscular pain. Suffer no longer, let us take care you.</p><p>After a quick assessment, the massage will be catered for you and your needs.</p>"
              linkTo="/prices"
              // linkTo="/prices#sports-massage"
            />

            <hr />

            <TreatmentSection
              id="physiotherapy"
              image={treatmentPhysiotherapyData}
              name="Physiotherapy"
              text="<p>Ideal for athletes, people with chronic conditions and people who want to take a proactive approach to their healthcare.</p><p>Supervised exercise rehabilitation as well as hands on modalities like sports massage is on offer alongside, education, advice and desk assessments can be provided.</p><p>The combined approach really gets the most out of each appointment and provides you with the knowledge and skills on how to continue your rehabilitation in your own time.</p>"
              textFlip
              linkTo="/prices"
              // linkTo="/prices#physiotherapy"
            />

            <p className="pt-5">90 minutes provides enough time for both.</p>

            <hr />

            <div>
              <p className="pb-10 text-center">
                All treatments are adherent to HCPC standards of proficiency and
                CSP quality assurance standards.
              </p>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                  <div className="flex justify-center">
                    <Logo
                      image={HcpcLogo}
                      title="Health Care Professions Council (HCPC)"
                      alt="Health Care Professions Council (HCPC)"
                    />
                  </div>
                  <div className="flex justify-center">
                    <Logo
                      image={CrestLogo}
                      title="Chartered Society of Physiotherapy (CSP)"
                      alt="Chartered Society of Physiotherapy (CSP)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default TreatmentsPage

const TreatmentSection = ({ id, image, name, text, textFlip, linkTo }) => (
  <div
    id={id}
    className="w-full lg:mx-auto md:grid md:grid-flow-col md:grid-cols-3 lg:grid-cols-2 md:gap-x-10"
  >
    <div
      className={cn({
        "lg:col-start-1": !textFlip,
        "lg:col-start-2": textFlip,
      })}
    >
      <GatsbyImage
        image={image}
        className="h-96 w-full lg:h-full"
        alt="" // Ignore as decoration only
      />
    </div>
    <div
      className={cn("md:col-span-2 lg:col-span-1 lg:place-self-center", {
        "lg:col-start-1": textFlip,
        "lg:col-start-2": !textFlip,
      })}
    >
      <div className="mx-auto text-base max-w-full lg:max-w-lg">
        <h3 className="mt-5 lg:mt-0 text-3xl font-header font-bold col-span-3 md:col-span-1">
          {name}
        </h3>
        <div
          className="mt-5 prose"
          dangerouslySetInnerHTML={{ __html: text }}
        />

        <div className="mt-4 flex justify-start">
          <div>
            <Link
              to={linkTo}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-gradiant-orange hover:bg-b-orange-dark"
            >
              See prices
              <svg
                width="20.84"
                height="11.401"
                viewBox="0 0 20.84 11.401"
                className="ml-2"
              >
                <path
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2px"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.331,8l4.095,4.287m0,0-4.095,4.287m4.095-4.287H3"
                  transform="translate(-2 -6.586)"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)
