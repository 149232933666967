import React from "react"
import { getSrc } from "gatsby-plugin-image"

const Logo = ({ title, alt, image }) => {
  const src = getSrc(image)

  return (
    <figure className="text-center">
      <img
        src={src}
        alt={alt}
        title={title}
        className="object-none h-48 w-full"
        loading="lazy"
        width={150}
      />
      <figcaption className="mt-2 text-sm text-gray-600">{title}</figcaption>
    </figure>
  )
}

export default Logo
